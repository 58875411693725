<template>
  <div class="reservation">
    <div class="container my-4">
      <div class="row">
        <div class="col-sm-8">
          <h1 class="h1 my-4" >Vous y êtes presque !</h1>
          <p class="lead">
            Réservez votre gîte <b>{{ gite.nom }}</b> afin d'en disposer
            au plus vite.
          </p>
          <hr>
          <p class="lead">
            Nous privilégions les contacts humains aux systèmes automatisés. 😊<br>
            Pour réserver, vous pouvez simplement nous contacter par e-mail
            ou par téléphone.
          </p>
          <ContactBox />
          <h2 class="h2 mt-5 pt-5">Conditions de location</h2>
          <ContenuConditions />
        </div>
        <div class="col-sm-4">
          <p>
            <b>Votre choix : </b>{{ gite.nom }}
            <img :src="imageUrls[0]" class="img-fluid rounded-lg shadow"/>
          </p>
         <p v-if="gite.prixWeekEnd > 0 && gite.prixSemaine > 0 && gite.prixMois > 0">
              <b>Prix</b><br>
              {{ gite.prixWeekEnd }}€ / week-end,<br>
              {{ gite.prixSemaine }}€ / semaine,<br>
              {{ gite.prixMois }}€ / mois.
          </p>
          <p v-else>
              <b>Prix</b><br>
              Sur devis.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactBox from '@/components/ContactBox.vue'
import ContenuConditions from '@/components/ContenuConditions.vue'

export default {
  name: 'Reservation',
  components: {
    ContactBox, ContenuConditions
  },
  computed: {
    gite () {
      return this.$store.state.gites[this.$route.params.id]
    },
    imageUrls () {
      const urls = []
      for (let i = 0; i < this.gite.imgs.length; i++) {
        urls.push('/imgs/' + this.gite.imgs[i])
      }
      return urls
    }
  }
}
</script>
